jQuery(document).ready(function(){
jQuery(document).on('focus',".valuefocus",function () {
    jQuery(this).parent().addClass("fieldfocus");
}).on('blur',".valuefocus",function () {
    checkIfEmpty();
}).on('click','body',function(){
    checkIfEmpty();
});
checkIfEmpty();
function checkIfEmpty() {
    jQuery('.valuefocus').each(function () {
        if (!jQuery(this).val()) {
            jQuery(this).parent().removeClass("fieldfocus");
        } else {
            jQuery(this).parent().addClass("fieldfocus");
        }
    });
}
});